import React from 'react'
import sketch from '../images/sketch.png';
import me from '../images/me.png';
import moment from 'moment';

export default function About() {

    let greetingText;
    const now = moment();
    const currentHour = now.hour();
    if ( currentHour < 12 ) {
        greetingText = 'Good morning.';
      } else if ( currentHour < 18 ) {
        greetingText = 'Good afternoon.';
      } else {
        greetingText = 'Good evening.';
      }

    return (
        <div className="about" id="about">
        <div className="container">
                <div className="section-title">
                    <h1>About</h1>
                </div>
            <div className="img">
                <img src={me} alt="about-me" />
            </div>
                <div className="about-content">
                    <div className="text">
                        <h4>{greetingText}</h4>
                        <p><div className="highlight">I'm Dani. A frontend developer, maker and problem solver.</div>
                        <p>My fascination with the digital world started with a need for a simple website, sparking a lifelong passion for the intersection of technology and creativity. This early curiosity soon evolved into a focused career in web development, with a special affinity for frontend development.</p>
                        <p>Throughout my professional journey, I've immersed myself in the dynamic and challenging realm of web technologies. My expertise spans from HTML/CSS to Angular, embracing the latest trends and tools in frontend development. What truly excites me is not just writing code, but crafting experiences that are both functional and aesthetically pleasing.</p>
                        <p>As a developer with an eye for design, I take pride in my ability to blend technical skills with a designer's perspective. Whether it's tweaking a user interface or experimenting with new layouts, I relish the opportunity to get involved in the design aspects of my projects. This blend of interests allows me to create websites that are not only efficient and user-friendly but also visually engaging.</p>
                        <p>For me, great frontend development is about more than creating websites; it's about building digital experiences that are intuitive, accessible, and delightful to users. By continuously learning and experimenting, I aim to keep pushing the boundaries of what's possible on the web, always with a keen eye for design and user experience.</p>
                        </p>
                    <div className="info">
                        <h4 className="tech-skills">Skills</h4>
                        <p>Angular / React / Vue / Python / Node / Collaboration / Agile / Design Systems / HTML and CSS / CMS Design & Architecture / Heroku / Firebase / Jest / Karma</p>
                    </div>
                        <div className="social-links">
                            <a href='https://www.linkedin.com/in/danielle-matton/' target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-linkedin fa-2x' />
                            </a>
                            <a href='https://github.com/dlynnea' target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-github fa-2x' />
                            </a>
                            <a href='https://medium.com/@danimatton' target='_blank' rel='noopener noreferrer'>
                                 <i className="fab fa-medium-m"></i>
                            </a>
                            <a href='https://www.youtube.com/channel/UCQOi5mHoizRD6CfirA1gHrQ?view_as=subscriber' target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-youtube fa-2x' />
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    )
}

